import { hotjar } from 'react-hotjar'

// function gtagInit (id) {
//   const script = document.createElement('script')
//   script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
//   script.onload = () => {
//     window.dataLayer = window.dataLayer || []
//     function gtag () {
//       dataLayer.push(arguments)
//     }
//     gtag('js', new Date())
//     gtag('config', id, {
//       send_page_view: true
//     })
//     gtag('event', 'screen_view', {
//       screen_name: window.location.pathname
//     })
//     gtag('config', id, {
//       page_path: window.location.pathname,
//       page_title: window.location.pathname,
//       page_location: window.location.pathname
//     })
//     window.gtag = gtag
//   }
//   window.document.head.appendChild(script)
// }

// function pixelInit (id) {
//   !(function (f, b, e, v, n, t, s) {
//     if (f.fbq) return
//     n = f.fbq = function () {
//       n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
//     }
//     if (!f._fbq) f._fbq = n
//     n.push = n
//     n.loaded = !0
//     n.version = '2.0'
//     n.queue = []
//     t = b.createElement(e)
//     t.async = !0
//     t.src = v
//     s = b.getElementsByTagName(e)[0]
//     s.parentNode.insertBefore(t, s)
//   })(
//     window,
//     document,
//     'script',
//     'https://connect.facebook.net/en_US/fbevents.js'
//   )
//   fbq('init', id)
//   fbq('track', 'PageView')
// }

export function initTracking () {
  if (typeof window !== 'undefined') {
    // const gaId = TRACKING_GA_ID
    // const pixelId = TRACKING_PIXEL_ID
    const hotjarId = TRACKING_HOTJAR_ID
    // if (gaId) {
    //   gtagInit(gaId)
    // }
    // if (pixelId) {
    //   pixelInit(pixelId)
    // }
    if (hotjarId) {
      hotjar.initialize(hotjarId, 6)
    }
  }
}

/**
 * @param transactionId
 * @param sku
 * @param itemName
 * @param price
 * @param total
 * @param coupon
 */
export function trackEComm (transactionId, sku, itemName, price, total, coupon) {
  try {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'purchase', {
        transaction_id: transactionId,
        value: total,
        tax: 0,
        shipping: 0,
        currency: 'USD',
        coupon: coupon,
        items: [
          // If someone purchases more than one item,
          // you can add those items to the items array
          {
            item_id: sku,
            item_name: itemName,
            index: 0,
            price: price,
            quantity: 1
          }
        ]
      })
    }
  } catch {}
}

/**
 * track Purchase event
 * @param transactionId
 * @param total: should be final price (after discounted if coupon was applied)
 * @param codeName: static string '3w2s' || 'LL'
 */
export function trackPurchase (transactionId, total, codeName = '3w2s') {
  let send_to = ''
  const gaPurchase4W = process.env.NEXT_PUBLIC_GA_PURCHASE_4W_SEND_TO
  const gaPurchase12W = process.env.NEXT_PUBLIC_GA_PURCHASE_12W_SEND_TO

  if (codeName?.toLowerCase() === '3w2s') {
    send_to = gaPurchase4W
  } else {
    send_to = gaPurchase12W
  }
  try {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', {
        send_to: send_to,
        value: total,
        currency: 'USD',
        transaction_id: transactionId
      })
    }
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'Purchase', {
        value: total,
        currency: 'USD',
        program: codeName
      })
    }
  } catch {}
}

export function trackPageViewSignup () {
  const gaSignUp = process.env.NEXT_PUBLIC_GA_SIGNUP_SEND_TO
  try {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', {
        send_to: gaSignUp
      })
    }
    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'ViewContent')
    }
  } catch {}
}
