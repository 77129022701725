import React, { Fragment, useEffect } from "react";
// eslint-disable-next-line camelcase
import { Roboto, Bebas_Neue, Lato } from "next/font/google";

import "helpers/constant";
import VBProgressBar from "components/share/progressbar";
import "styles/main.scss";
import smoothscroll from "smoothscroll-polyfill";
import Tracking from "../components/tracking";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config"; // Import the CSS
import Script from "next/script";
import { GoogleOAuthProvider } from "@react-oauth/google";
config.autoAddCss = false;

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    environment: "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const robotoFont = Roboto({
  display: "swap",
  subsets: ["latin"],
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
});

const bebasNeueFont = Bebas_Neue({
  display: "swap",
  subsets: ["latin"],
  weight: ["400"],
});

const latoFont = Lato({
  display: "swap",
  subsets: ["latin"],
  weight: ["900"],
});

function MyApp({ Component, pageProps, err }) {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);
  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
      <Fragment>
        <style jsx global>{`
          :root {
            --font-roboto: ${robotoFont.style.fontFamily};
            --font-bebas-neue: ${bebasNeueFont.style.fontFamily};
            --font-lato: ${latoFont.style.fontFamily};
          }
        `}</style>
        <link rel="preload" imageSrcSet="/static/images/famous-poster.jpg" as="image" /> <VBProgressBar />
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${TRACKING_GA_ID}`} />
        <Script id="google-analytics">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', '${TRACKING_GA_ID}', {
            send_page_view: true
          });
          gtag('event', 'screen_view', {
            screen_name: window.location.pathname
          });
          gtag('config', '${TRACKING_GA_ID}', {
            page_path: window.location.pathname,
            page_title: window.location.pathname,
            page_location: window.location.pathname
          });
          window.gtag = gtag;
        `}
        </Script>
        <Script
          id="fb-pixel"
          dangerouslySetInnerHTML={{
            __html: `
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }
            if (!f._fbq) f._fbq = n
            n.push = n
            n.loaded = !0
            n.version = '2.0'
            n.queue = []
            t = b.createElement(e)
            t.async = !0
            t.src = v
            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
          })(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js'
          )
          fbq('init', ${TRACKING_PIXEL_ID})
          fbq('track', 'PageView')
          `,
          }}
        />
        <Tracking pageComponent={Component} />
        <Component {...pageProps} err={err} />
      </Fragment>
    </GoogleOAuthProvider>
  );
}

export default MyApp;
